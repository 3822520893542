export function getBrowserLanguageCode(): "en" | "ko" {
  const code = navigator.language?.slice(0, 2);
  if (code === "ko") return "ko";
  else return "en";
}

export function getBrowserLanguageName(): string {
  const code = getBrowserLanguageCode();
  switch (code) {
    case "ko":
      return "Korean";

    case "en":
    default:
      return "English";
  }
}
