import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type User = {
  id: string;
  username: string;
  imageUrl: string;
};
type AuthState =
  | { state: "LOADING" }
  | { state: "ANONYMOUS" }
  | { state: "AUTHENTICATED"; user: User };

const initialState: AuthState = {
  state: "LOADING",
};

const authSlice = createSlice({
  name: "auth",
  initialState: <AuthState>initialState,
  reducers: {
    setLoading: (state) => {
      state.state = "LOADING";
    },
    setAnonymous: (state) => {
      state.state = "ANONYMOUS";
    },
    setAuthenticated: (state, action: PayloadAction<User>) => {
      state.state = "AUTHENTICATED";
      (<any>state).user = action.payload;
    },
  },
});

export const authReducer = authSlice.reducer;
export const { setLoading, setAnonymous, setAuthenticated } = authSlice.actions;
