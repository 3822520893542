import React, { useCallback, useEffect, useMemo, useState } from "react";

import CurationGridList from "../component/CurationGridList";
import LoadMoreButton from "../component/LoadMoreButton";
import DayTripApiClient, {
  DayTripApiResponse,
} from "../client/DayTripApiClient";

type Data = DayTripApiResponse<typeof DayTripApiClient.route.getMainCuration>;

const MainCurationPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Data | null>(null);

  const load = useCallback(async () => {
    try {
      setIsLoading(true);
      setData(null);

      const response = await DayTripApiClient.route.getMainCuration();
      setData(response);
    } catch (e) {
      if (e instanceof Error) alert(e.message);
      else alert("Unknown error");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onLoadMore = useCallback(async () => {
    if (data === null) return;

    try {
      setIsLoading(true);

      const response = await DayTripApiClient.route.getMainCuration(data.next);
      setData({
        ...data,
        next: response.next,
        page: [...data.page, ...response.page],
      });
    } catch (e) {
      if (e instanceof Error) alert(e.message);
      else alert("Unknown error");
    } finally {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    load().then();
  }, []);

  const curations = useMemo(() => {
    if (data === null) return [];

    return data.page.map((item) => ({
      id: item.id,
      imageUrl: item.coverImage.url,
      title: item.name,
      username: item.ownerUser.username,
    }));
  }, [data]);

  return (
    <div className="d-grid grid-column gap-5">
      {!isLoading && curations.length === 0 && (
        <p className="text-center text-secondary">No Curations, yet.</p>
      )}

      {curations.length > 0 && <CurationGridList curations={curations} />}

      {isLoading && (
        <div className="d-flex justify-content-center text-body-tertiary">
          <div className="spinner-border spinner-border-sm" />
        </div>
      )}

      {curations.length > 0 && (
        <LoadMoreButton
          current={curations.length}
          hasNext={data !== null && data.next !== ""}
          onLoadMore={onLoadMore}
        />
      )}
    </div>
  );
};

export default MainCurationPage;
