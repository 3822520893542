import { useCallback } from "react";
import { useAppSelector } from "../../../store";
import { usePageContext } from "../PageContext";
import DayTripApiClient from "../../../client/DayTripApiClient";

export const useSpace = () => {
  const auth = useAppSelector((state) => state.auth);
  const { state, dispatch } = usePageContext();

  const loadSpace = useCallback(
    async (spaceId: string) => {
      if (auth.state !== "AUTHENTICATED") {
        return;
      }

      try {
        const space = await DayTripApiClient.route.getSpaceDetail(spaceId);
        dispatch({
          type: "ADD_SPACE",
          payload: {
            id: spaceId,
            value: {
              id: space.id,
              name: space.name,
              image: space.images[0]?.url ?? "",
              category: space.tag.primaryPlaceType?.name ?? "",
              price:
                space.information.priceLevel > 0
                  ? space.information.currencySymbol.repeat(
                      space.information.priceLevel,
                    )
                  : "",
              openStatus: space.information.operationalStatus,
              openStatusDescription: space.information.operationalStatusName,
              operationDescription: space.information.operationalDescription,
              googlePlaceId: space.tag.placeId,
              websiteUrl: space.information.website,
            },
          },
        });
      } catch {
        dispatch({ type: "ADD_SPACE", payload: { id: spaceId, value: null } });
      }
    },
    [state],
  );

  return { spaceRecord: state.spaceRecord, loadSpace };
};
