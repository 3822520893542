import { useCallback } from "react";
import { useAppSelector } from "../../../store";
import { useAuthRequest } from "../../../AuthRequiredPage";
import { usePageContext } from "../PageContext";
import DayTripApiClient from "../../../client/DayTripApiClient";

// const baseURL = "http://10.140.160.148:8765";
const baseURL = "https://chatbot.daytripapp.org";

async function* createChatStream(
  sessionId: string,
  message: string,
): AsyncGenerator<any, void> {
  const response = await fetch(baseURL + "/chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "text/event-stream",
    },
    body: JSON.stringify({ session_id: sessionId, content: message }),
  });

  if (response.body === null) return;

  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    const lines = value.split("\n");
    for (const line of lines) {
      try {
        const jsonString = line.replace(/^data: /, "");
        yield JSON.parse(jsonString);
      } catch {
        // ignore
      }
    }
  }
}

export const useChat = () => {
  const auth = useAppSelector((state) => state.auth);
  const { requestAuth } = useAuthRequest();

  const { state, dispatch } = usePageContext();

  const checkAuth = useCallback(() => {
    if (auth.state !== "AUTHENTICATED") {
      requestAuth();
    }
  }, []);

  const getOrCreateSession = useCallback(
    async (userId: string): Promise<string> => {
      if (state.sessionId !== null) return state.sessionId;

      const location = await DayTripApiClient.route.getUserLocationMetadata();

      const response = await fetch(baseURL + "/sessions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: userId,
          language: state.language.name,
          area: location.city || location.regionName,
        }),
      });
      const data = await response.json();

      dispatch({ type: "SET_SESSION_ID", payload: data.session_id });
      return data.session_id;
    },
    [state.sessionId],
  );

  const send = useCallback(
    async (message: string) => {
      if (state.isGenerating) {
        return;
      }

      if (auth.state !== "AUTHENTICATED") {
        requestAuth();
        return;
      }

      let sid = await getOrCreateSession(auth.user.id);

      try {
        dispatch({ type: "SET_IS_GENERATING", payload: true });

        const stream = createChatStream(sid, message);

        const newChatItems = state.chatItems.map((chatItem) => {
          if (chatItem.role === "user") {
            return chatItem;
          }

          chatItem.messages = chatItem.messages.filter(
            (message) => message.type !== "question-suggestions",
          );

          return chatItem;
        });

        for await (const message of stream) {
          const message_id = message["id"];
          const message_source = message["source"];
          const message_type = message["type"];
          const message_content = message["content"];

          if (message_source === "user") {
            newChatItems.push({
              role: "user",
              message: { id: message_id, content: message_content },
            });
          } else {
            let lastChatItem = newChatItems[newChatItems.length - 1];

            if (!lastChatItem || lastChatItem.role !== "bot") {
              lastChatItem = { role: "bot", messages: [] };
              newChatItems.push(lastChatItem);
            }

            let lastMessage =
              lastChatItem.messages[lastChatItem.messages.length - 1];

            if (message_type === "text_chunk") {
              if (
                lastMessage === undefined ||
                lastMessage.id !== message_id ||
                lastMessage.type !== "text"
              ) {
                lastMessage = {
                  type: "text",
                  id: message_id,
                  content: message_content,
                };
                lastChatItem.messages.push(lastMessage);
              } else {
                lastMessage.content += message_content;
              }
            } else if (message_type === "space_recommendation") {
              lastMessage = {
                type: "space-list",
                id: message_id,
                spaces: JSON.parse(message_content)["results"].map(
                  (value: any) => ({
                    id: value["space_id"],
                    reason: value["reason"],
                  }),
                ),
              };
              lastChatItem.messages.push(lastMessage);
            } else if (message_type === "related_question_suggest") {
              lastMessage = {
                type: "question-suggestions",
                id: message_id,
                suggestions: JSON.parse(message_content)["suggestions"],
              };
              lastChatItem.messages.push(lastMessage);
            }
          }

          dispatch({ type: "SET_CHAT_ITEMS", payload: newChatItems });
        }
      } finally {
        dispatch({ type: "SET_IS_GENERATING", payload: false });
      }
    },
    [auth, state, getOrCreateSession],
  );

  return {
    sessionId: state.sessionId,
    chatItems: state.chatItems,
    isGenerating: state.isGenerating,
    checkAuth,
    send,
  };
};
