import React from "react";

interface LoadMoreButtonProps {
  current: number;
  total?: number;
  hasNext: boolean;
  onLoadMore?: () => void;
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = (props) => {
  return (
    <div className="d-flex flex-column gap-3">
      {props.hasNext && (
        <div className="d-flex flex-row px-5">
          <hr className="flex-fill" />
          <button className="btn btn-sm mx-5" onClick={props.onLoadMore}>
            Load More
          </button>
          <hr className="flex-fill" />
        </div>
      )}
      {props.total !== undefined && (
        <div className="d-flex flex-column align-items-center">
          <div className="progress mb-1" style={{ width: 100, height: 3 }}>
            <div
              className="progress-bar bg-dark"
              style={{ width: `${(props.current / props.total) * 100}%` }}
            ></div>
          </div>
          <small className="text-body-secondary">
            {props.current.toLocaleString()} of {props.total.toLocaleString()}
          </small>
        </div>
      )}
    </div>
  );
};

export default LoadMoreButton;
