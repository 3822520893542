import { Client } from "../client";

type Request = DayTripApiService.Request<{ next?: string; limit?: number }>;

type Response = DayTripApiService.Response<{
  next: string;
  model: string;
  page: {
    id: string;
    images: { id: string; url: string }[];
    video: { id: string; url: string };
    description: {
      checksum: string;
      value: string;
      originalValue: string;
      language: string;
      originalLanguage: string;
      isTranslated: true;
    };
    shortDescription: {
      checksum: string;
      value: string;
      language: string;
      isTranslated: true;
    };
    premiumIndex: number;
    option: {
      isPrivate: true;
      isBlocked: true;
      isCurated: true;
      isLike: true;
      sponsorType: string;
    };
    summary: {
      clapCount: number;
      clappedByCurrentUserCount: number;
      likeCount: number;
      exposureCount: number;
      readCount: number;
    };
    meta: {
      originalLanguage: string;
    };
    visitedAt: string;
    createdAt: string;
    updatedAt: string;
    space: {
      id: string;
      name: string;
      origin: string;
      location: {
        coordinate: { lat: number; lng: number };
        address: string;
        distance: number;
      };
      tag: {
        regions: { id: string; name: string }[];
        areas: { id: string; type: string; name: string }[];
        spaceTypes: { id: string; name: string }[];
        placeTypes: { type: string; categoryType: string; name: string }[];
        primaryPlaceType: { type: string; categoryType: string; name: string };
        placeId: string;
        badges: { type: string; name: string }[];
      };
      option: {
        isCurated: true;
        isSaved: true;
        hasVisited: true;
        isOperationalGooglePlacePOI: true;
      };
      information: {
        openingHours: string[];
        operationalStatus: string;
        operationalStatusName: string;
        operationalDescription: string;
        website: string;
        phoneNumber: string;
        currencySymbol: string;
        priceLevel: number;
        isOpen: true;
      };
      summary: {
        saveUserCount: number;
        popularityScore: number;
      };
      createdAt: string;
      updatedAt: string;
    };
    ownerUser: {
      id: string;
      code: string;
      username: string;
      photoUrl: string;
      intro: {
        checksum: string;
        value: string;
        language: string;
        isTranslated: true;
      };
      option: {
        isBusiness: true;
        isCelebrity: true;
        isCurator: true;
        isPrivate: true;
        hasDayTripBadge: true;
        isContentsRecommended: true;
        curatorApplicationStatus: string;
        monetizeApplicationStatus: string;
      };
      meta: {
        isFollowing: true;
        isFollowRequested: true;
        hasNewPost: true;
      };
      summary: {
        daylogCount: number;
        curationCount: number;
        checkinCount: number;
        followerUserCount: number;
        followingUserCount: number;
      };
      createdAt: string;
      updatedAt: string;
    };
  }[];
  totalElementCount: number;
}>;

export const getMainDaylog = (next?: string): Promise<Response> =>
  Client.request<Request, Response>("GET", `/api/v1/feed/daylog:byAreaSimple`, {
    params: { next: next, limit: 50 },
  });
