import React, { useCallback, useEffect } from "react";
import { useAppSelector } from "./store";
import { useLocation, useNavigate } from "react-router-dom";

export const useAuthRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const requestAuth = useCallback(() => {
    navigate("/sign-in", { state: { from: location } });
  }, []);

  return { requestAuth };
};

const AuthRequiredPage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { requestAuth } = useAuthRequest();
  const authState = useAppSelector((state) => state.auth.state);

  useEffect(() => {
    if (authState === "ANONYMOUS") {
      requestAuth();
    }
  }, [authState]);

  return <>{children}</>;
};

export default AuthRequiredPage;
