import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCTJBfBBw1py2Riu6g8nDpmYs9WSVi6yNQ",
  authDomain: "daytrip-a9354.firebaseapp.com",
  projectId: "daytrip-a9354",
  storageBucket: "daytrip-a9354.appspot.com",
  messagingSenderId: "517187607981",
  appId: "1:517187607981:web:5fcce9e9b19b691753d524",
  measurementId: "G-0M9K2J39DY",
};

const app = initializeApp(firebaseConfig);

const authService = getAuth(app);

export { authService };
