import React, { useCallback } from "react";
import ArrowUpSvg from "../asset/arrow-up.svg";
import PresetQuestions from "./PresetQuestions";
import styles from "../style/BottomBar.module.scss";
import { PresetQuestion } from "../types";
import Text from "../asset/text.json";
import { useLanguage } from "../service/useLanguage";
import { useChat } from "../service/useChat";

type BottomBarProps = {
  onSubmit: (value: string) => void;
  presetQuestions?: PresetQuestion[] | null;

  isProcessing?: boolean;
};

const BottomBar: React.FC<BottomBarProps> = (props) => {
  const { code } = useLanguage();
  const { checkAuth } = useChat();

  const [text, setText] = React.useState<string>("");
  const [isComposing, setIsComposing] = React.useState(false);

  const onInputFocus = useCallback(() => {
    checkAuth();
  }, []);

  const onSubmit = useCallback(() => {
    if (text === "") return;

    props.onSubmit(text);
    setText("");
  }, [text, props.onSubmit]);

  const onPresetQuestionSelect = useCallback(
    (question: string) => {
      props.onSubmit(question);
      setText("");
    },
    [props.onSubmit],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && !isComposing) {
        onSubmit();
      }
    },
    [onSubmit, isComposing],
  );

  return (
    <div className={styles.container}>
      {props.presetQuestions !== undefined &&
        props.presetQuestions !== null && (
          <div className={styles.presetQuestionsPositioner}>
            <div className={styles.presetQuestionWrapper}>
              <PresetQuestions
                questions={props.presetQuestions}
                onSelect={onPresetQuestionSelect}
              />
            </div>
          </div>
        )}

      <div className={styles.inputWrapper}>
        <input
          className={styles.input}
          placeholder={Text[code].CHAT_INPUT_PLACEHOLDER}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onFocus={onInputFocus}
          onKeyDown={handleKeyDown}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        <button
          className={styles.submitButton}
          onClick={onSubmit}
          disabled={text === "" || props.isProcessing}
        >
          {props.isProcessing ? (
            <div className="spinner-border spinner-border-sm" />
          ) : (
            <img src={ArrowUpSvg} alt="" className={styles.submitIcon} />
          )}
        </button>
        <div />
      </div>
      <label className={styles.noticeText}>{Text[code].WARNING_TEXT}</label>
    </div>
  );
};

export default BottomBar;
