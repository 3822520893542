import React, { useCallback, useEffect, useState } from "react";
import MultilineText from "../component/MultilineText";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import DayTripApiClient from "../client/DayTripApiClient";

type Data = {
  imageUrls: string[];
  spaceName: string;
  spaceType: string;
  spaceAreas: string;
  userId: string;
  userImageUrl: string;
  userUsername: string;
  createdAt: string;
  description: string;
};

const DaylogDetailPage: React.FC = () => {
  const navigate = useNavigate();
  let { daylog_id: daylogId } = useParams();

  const [data, setData] = useState<Data | null>(null);

  const load = useCallback(async () => {
    if (daylogId === undefined) return;

    try {
      const apiData = await DayTripApiClient.route.getDaylogDetail(daylogId);
      setData({
        imageUrls: apiData.images.map((v) => v.url),
        spaceName: apiData.space.name,
        spaceType: apiData.space.tag.primaryPlaceType.name,
        spaceAreas: apiData.space.tag.areas.map((v) => v.name).join(", "),
        userId: apiData.ownerUser.id,
        userImageUrl: apiData.ownerUser.photoUrl,
        userUsername: apiData.ownerUser.username,
        createdAt: apiData.createdAt,
        description: apiData.description.value,
      });
    } catch (e) {
      if (e instanceof Error) alert(e.message);
      else alert("Unknown Error");
    }
  }, [daylogId]);

  useEffect(() => {
    load().then();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-4 offset-2">
          <ImageCarousel imageUrls={data?.imageUrls ?? []} />
        </div>
        <div className="col-4">
          <div className="mb-5">
            <h5>{data?.spaceName ?? ""}</h5>
            <p className="mb-0 text-body-secondary small">
              <span>{data?.spaceType ?? ""}</span>
              <i className="bi bi-dot"></i>
              <span>{data?.spaceAreas ?? ""}</span>
            </p>
          </div>
          <div>
            <div
              className="d-flex flex-row align-items-center mb-3"
              style={{ cursor: "pointer" }}
              onClick={() => data !== null && navigate(`/user/${data.userId}`)}
            >
              <img
                className="rounded-circle"
                src={data?.userImageUrl ?? ""}
                alt=""
                width={46}
                height={46}
              />
              <p className="ms-2 mb-0 lh-sm small">
                <strong>{data?.userUsername ?? ""}</strong>
                <br />
                <span className="text-body-secondary">
                  {data?.createdAt ?? ""}
                </span>
              </p>
            </div>
            <p className="mb-0" style={{ fontSize: 14 }}>
              <MultilineText value={data?.description ?? ""} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DaylogDetailPage;

type ImageCarouselProps = {
  imageUrls: string[];
};

const ImageCarousel: React.FC<ImageCarouselProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div
      className="w-100 position-relative bg-secondary-subtle"
      style={{ aspectRatio: 0.7 }}
    >
      <img
        key={props.imageUrls[selectedIndex]}
        className="w-100 h-100 object-fit-cover"
        src={props.imageUrls[selectedIndex]}
        alt=""
      />

      <div className="position-absolute w-100 h-100 top-0 left-0 d-flex flex-column justify-content-end">
        <div className="vertical-gradient-overlay p-3">
          <div
            className="d-flex flex-row justify-content-center gap-2"
            style={{ fontSize: 8 }}
          >
            {props.imageUrls.map((_, i) => (
              <i
                key={i}
                className={clsx(
                  "bi bi-circle-fill text-white",
                  i !== selectedIndex && "text-opacity-25",
                )}
              ></i>
            ))}
          </div>
        </div>
      </div>
      <div className="position-absolute top-0 h-100 w-100 d-flex flex-row justify-content-between text-white h4">
        <div
          className="p-3 h-100 d-flex flex-column justify-content-center"
          style={{ cursor: "pointer" }}
          onClick={() =>
            setSelectedIndex(
              selectedIndex > 0
                ? selectedIndex - 1
                : props.imageUrls.length - 1,
            )
          }
        >
          <i className="bi bi-arrow-left-circle-fill"></i>
        </div>
        <div
          className="p-3 h-100 d-flex flex-column justify-content-center"
          style={{ cursor: "pointer" }}
          onClick={() =>
            setSelectedIndex(
              selectedIndex < props.imageUrls.length - 1
                ? selectedIndex + 1
                : 0,
            )
          }
        >
          <i className="bi bi-arrow-right-circle-fill"></i>
        </div>
      </div>
    </div>
  );
};
