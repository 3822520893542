import React, { useCallback, useEffect, useState } from "react";

import { Outlet, useMatch, useNavigate } from "react-router-dom";
import clsx from "clsx";
import MultilineText from "../component/MultilineText";
import DayTripApiClient from "../client/DayTripApiClient";

type Data = {
  username: string;
  imageUrl: string;
  followerCount: number;
  followingCount: number;
  description: string;
  daylogCount: number;
  curationCount: number;
};

const UserDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const match = useMatch("/user/:user_id/*");

  const [data, setData] = useState<Data | null>(null);

  const category = match?.params["*"] === "daylog" ? "DAYLOG" : "CURATION";

  const load = useCallback(async () => {
    if (match?.params.user_id === undefined) return;

    try {
      const response = await DayTripApiClient.route.getUserDetail(
        match.params.user_id,
      );
      setData({
        username: response.username,
        imageUrl: response.photoUrl,
        followerCount: response.summary.followerUserCount,
        followingCount: response.summary.followingUserCount,
        description: response.intro.value,
        daylogCount: response.summary.daylogCount,
        curationCount: response.summary.curationCount,
      });
    } catch (e) {
      if (e instanceof Error) alert(e.message);
      else alert("Unknown error");
    }
  }, [match?.params.user_id]);

  useEffect(() => {
    load().then();
  }, [match?.params.user_id]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-4">
          <div className="position-sticky card p-4" style={{ top: 100 }}>
            <div className="d-flex flex-row align-items-center">
              <img
                className="rounded-circle"
                src={data?.imageUrl}
                alt=""
                width={64}
                height={64}
              />
              <div className="ms-3">
                <h5 className="mb-1">{data?.username}</h5>
                <p className="mb-0" style={{ fontSize: 13 }}>
                  <span className="me-1">Follower</span>
                  <strong>{data?.followerCount.toLocaleString() ?? 0}</strong>
                  <span className="mx-2">|</span>
                  <span className="me-1">Following</span>
                  <strong>{data?.followingCount.toLocaleString() ?? 0}</strong>
                </p>
              </div>
            </div>
            {data !== null && data.description.length > 0 && (
              <p className="mt-4 mb-0" style={{ fontSize: 12 }}>
                <MultilineText value={data?.description ?? ""} />
              </p>
            )}
          </div>
        </div>
        <div className="col-8">
          <div
            className="d-flex flex-row gap-3 mb-4"
            style={{ marginLeft: "-1rem" }}
          >
            <div
              className={clsx(
                "h5",
                "p-3",
                "mb-0",
                category === "CURATION" ? "text-black" : "text-body-tertiary",
              )}
              style={{
                cursor: category === "CURATION" ? "default" : "pointer",
              }}
              onClick={() =>
                category !== "CURATION" &&
                navigate(`${match?.pathnameBase}/curation`)
              }
            >
              Curation<span className="ms-2">{data?.curationCount ?? 0}</span>
            </div>
            <div
              className={clsx(
                "h5",
                "p-3",
                "mb-0",
                category === "DAYLOG" ? "text-black" : "text-body-tertiary",
              )}
              style={{ cursor: category === "DAYLOG" ? "default" : "pointer" }}
              onClick={() =>
                category !== "DAYLOG" &&
                navigate(`${match?.pathnameBase}/daylog`)
              }
            >
              Daylog<span className="ms-2">{data?.daylogCount ?? 0}</span>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserDetailPage;
