import React from "react";
import CurationItem from "./CurationItem";
import clsx from "clsx";

interface CurationGridListProps {
  columns?: number;
  curations: {
    id: string;
    imageUrl: string;
    title: string;
    username: string;
  }[];
}

const CurationGridList: React.FC<CurationGridListProps> = (props) => {
  return (
    <div className={clsx("row g-2", `row-cols-${props.columns ?? 4}`)}>
      {props.curations.map((item) => (
        <div key={item.id} className="col">
          <CurationItem
            id={item.id}
            imageUrl={item.imageUrl}
            title={item.title}
            username={item.username}
          />
        </div>
      ))}
    </div>
  );
};

export default CurationGridList;
