import React from "react";
import PageLayout from "./component/PageLayout";
import { PageContextProvider } from "./PageContext";

const ChatbotPage: React.FC = () => {
  return (
    <PageContextProvider>
      <PageLayout />
    </PageContextProvider>
  );
};

export default ChatbotPage;
