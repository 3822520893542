import { Client } from "../client";

type Request = DayTripApiService.Request<void, {}>;

type Response = DayTripApiService.Response<{
  id: string;
  code: string;
  username: string;
  photoUrl: string;
}>;

export const createProfile = (): Promise<Response> =>
  Client.request<Request, Response>("POST", `/api/v1/users`, { data: {} });
