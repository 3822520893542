import React from "react";
import DaylogItem from "./DaylogItem";
import clsx from "clsx";

interface DaylogGridListProps {
  columns?: number;
  daylogs: {
    id: string;
    imageUrl: string;
    spaceName: string;
    username: string;
    areas: string;
  }[];
}

const DaylogGridList: React.FC<DaylogGridListProps> = (props) => {
  return (
    <div className={clsx("row g-2", `row-cols-${props.columns ?? 4}`)}>
      {props.daylogs.map((item) => (
        <div key={item.id} className="col">
          <DaylogItem
            id={item.id}
            imageUrl={item.imageUrl}
            spaceName={item.spaceName}
            username={item.username}
            areas={item.areas}
          />
        </div>
      ))}
    </div>
  );
};

export default DaylogGridList;
