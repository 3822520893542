import * as route from "./route";
import { healthcheck } from "./healthcheck";
import { Client } from "./client";

const DayTripApiClient = {
  setTokenGetter: Client.setTokenGetter.bind(Client),
  healthcheck: healthcheck,
  route: route,
};

export type DayTripApiResponse<T extends (...args: any) => any> = Awaited<
  ReturnType<T>
>;

export default DayTripApiClient;
