import React, { useCallback, useEffect, useState } from "react";
import DayTripApiClient from "../client/DayTripApiClient";
import { useNavigate } from "react-router-dom";
import SpaceItem from "../component/SpaceItem";
import SpaceSelector from "../component/SpaceSelector";

type Space = { id: string; name: string; imageUrl: string; address: string };

type ContentItem = {
  title: string;
  description: string;
  space: (Space & { daylogId: string }) | null;
};

const emptyItem = { title: "", description: "", space: null };

const NewCurationPage: React.FC = () => {
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [coverImageUrl, setCoverImageUrl] = React.useState<string | null>(null);
  const [items, setItems] = React.useState<ContentItem[]>([emptyItem]);

  const [isCreating, setIsCreating] = React.useState(false);

  const navigate = useNavigate();

  const onCreate = useCallback(async () => {
    if (title === "") {
      alert("Title cannot be empty");
      return;
    }

    if (coverImageUrl === null) {
      alert("Cover image cannot be empty. Please include at least one item.");
      return;
    }

    if (items.length === 0) {
      alert("Curation must include at least one item.");
      return;
    }

    for (const item of items) {
      if (item.space === null) {
        alert("All items must reference valid space.");
        return;
      }
    }

    try {
      setIsCreating(true);

      const result = await DayTripApiClient.route.createCuration({
        data: {
          title: title,
          description: description,
          coverImageUrl: coverImageUrl,
          contentBlocks: items.map((v) => ({
            title: v.title,
            description: v.description,
            daylogId: v.space!.daylogId,
          })),
          targetCommunities: [],
          option: { isPrivate: false },
        },
      });
      navigate(`/curation/${result.id}`);
    } catch (e) {
      if (e instanceof Error) alert(e.message);
      else alert("Unknown error");
    } finally {
      setIsCreating(false);
    }
  }, [title, description, coverImageUrl, items]);

  useEffect(() => {
    setCoverImageUrl(
      items.length === 0 || items[0].space === null
        ? null
        : items[0].space!.imageUrl,
    );
  }, [items]);

  return (
    <div className="container">
      <h4 className="mb-5">Create New Curation</h4>
      <div className="row">
        <div className="col-4">
          <div
            className="bg-secondary-subtle rounded overflow-hidden mb-3 d-flex justify-content-center align-items-center"
            style={{ aspectRatio: 0.7 }}
          >
            {coverImageUrl === null ? (
              <i
                className="bi bi-image-fill text-white"
                style={{ fontSize: 90 }}
              ></i>
            ) : (
              <img
                src={coverImageUrl}
                alt=""
                className="w-100 h-100"
                style={{ aspectRatio: 0.7 }}
              />
            )}
          </div>
        </div>
        <div className="col-8 d-flex flex-column gap-5">
          <input
            className="form-control"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <textarea
            className="form-control"
            placeholder="Description"
            rows={8}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {items.map((item, index) => (
            <CurationItem
              key={index}
              index={index + 1}
              item={item}
              onChange={(value) => {
                items[index] = value;
                setItems([...items]);
              }}
              onDelete={() => {
                items.splice(index, 1);
                setItems([...items]);
              }}
            />
          ))}
          <button
            className="btn btn-outline-dark"
            onClick={() => setItems([...items, emptyItem])}
          >
            <i className="bi bi-plus-circle me-2"></i>
            Add Item
          </button>

          <button
            className="btn btn-dark"
            onClick={onCreate}
            disabled={isCreating}
          >
            {isCreating ? (
              <div className="spinner-border spinner-border-sm" />
            ) : (
              "Create"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewCurationPage;

type CurationItemProps = {
  index: number;
  item: ContentItem;
  onChange: (item: ContentItem) => void;
  onDelete: () => void;
};

const CurationItem: React.FC<CurationItemProps> = (props) => {
  const [isSpaceLoading, setIsLoading] = useState(false);

  const loadSpace = useCallback(
    async (space: Space) => {
      try {
        setIsLoading(true);

        const response = await DayTripApiClient.route.getDaylogOfSpace(
          space.id,
        );

        if (response.page.length === 0) {
          alert("No Daylog exists in Space");
          return;
        }

        const daylogId = response.page[0].id;
        props.onChange({
          ...props.item,
          space: { ...space, daylogId: daylogId },
        });
      } catch (e) {
        if (e instanceof Error) alert(e.message);
        else alert("Unknown error");
      } finally {
        setIsLoading(false);
      }
    },
    [props.onChange],
  );

  return (
    <div className="d-flex flex-row">
      <h4 className="me-4 py-1">{props.index}</h4>
      <div className="flex-fill d-flex flex-column gap-3">
        <div className="d-flex flex-row">
          <input
            className="form-control me-3"
            placeholder="Title (Optional)"
            value={props.item.title}
            onChange={(e) =>
              props.onChange({ ...props.item, title: e.target.value })
            }
          />
          <button className="btn btn-outline-danger" onClick={props.onDelete}>
            <i className="bi bi-x-lg"></i>
          </button>
        </div>
        <textarea
          className="form-control"
          placeholder="Description (Optional)"
          rows={4}
          value={props.item.description}
          onChange={(e) =>
            props.onChange({ ...props.item, description: e.target.value })
          }
        />
        {props.item.space === null ? (
          <SpaceSelector onSelect={loadSpace} />
        ) : isSpaceLoading ? (
          <div className="d-flex justify-content-center py-3 text-body-tertiary">
            <div className="spinner-border spinner-border-sm" />
          </div>
        ) : (
          <SpaceItem value={props.item.space} />
        )}
      </div>
    </div>
  );
};
