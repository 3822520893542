import { Client } from "../client";

type Request = DayTripApiService.Request<{ next?: string }>;

type Response = DayTripApiService.Response<{
  model: string;
  next: string;
  page: {
    id: string;
    name: string;
    description: string;
    coverImage: { url: string };
    meta: {
      originalLanguage: string;
      isCurated: boolean;
      spaceCount: number;
    };
    option: {
      isSaved: boolean;
      isPrivate: boolean;
      isLike: boolean;
      isBlocked: boolean;
    };
    summary: {
      clapCount: number;
      clappedByCurrentUserCount: number;
      likeCount: number;
      saveUserCount: number;
      clickCount: number;
      exposureCount: number;
    };
    createdAt: string;
    updatedAt: string;
    curationItems: {
      title: string;
      description: string;
      daylogId: string;
      daylog: {
        id: string;
        images: { id: string; url: string }[];
        video: { id: string; url: string };
        description: {
          checksum: string;
          value: string;
          originalValue: string;
          language: string;
          originalLanguage: string;
          isTranslated: boolean;
        };
        shortDescription: {
          checksum: string;
          value: string;
          language: string;
          isTranslated: boolean;
        };
        premiumIndex: number;
        option: {
          isPrivate: boolean;
          isBlocked: boolean;
          isCurated: boolean;
          isLike: boolean;
          sponsorType: string;
        };
        summary: {
          clapCount: number;
          clappedByCurrentUserCount: number;
          likeCount: number;
          exposureCount: number;
          readCount: number;
        };
        meta: { originalLanguage: string };
        visitedAt: string;
        createdAt: string;
        updatedAt: string;
      };
    }[];
    ownerUser: {
      id: string;
      code: string;
      username: string;
      photoUrl: string;
      intro: {
        checksum: string;
        value: string;
        language: string;
        isTranslated: boolean;
      };
      option: {
        isBusiness: boolean;
        isCelebrity: boolean;
        isCurator: boolean;
        isPrivate: boolean;
        hasDayTripBadge: boolean;
        isContentsRecommended: boolean;
        curatorApplicationStatus: string;
        monetizeApplicationStatus: string;
      };
      meta: {
        isFollowing: boolean;
        isFollowRequested: boolean;
        hasNewPost: boolean;
      };
      summary: {
        daylogCount: number;
        curationCount: number;
        checkinCount: number;
        followerUserCount: number;
        followingUserCount: number;
      };
      createdAt: string;
      updatedAt: string;
    };
  }[];
  totalElementCount: number;
}>;

export const getUserCuration = (
  userId: string,
  next?: string,
): Promise<Response> =>
  Client.request<Request, Response>(
    "GET",
    `/api/v1/content/curation/${userId}:byUserSimple`,
    { params: { next: next } },
  );
