import React, { createContext, useContext, useReducer } from "react";
import { ChatItem, Space } from "./types";
import { getBrowserLanguageCode, getBrowserLanguageName } from "../../util";

interface PageState {
  language: {
    code: "en" | "ko";
    name: string;
  };
  sessionId: string | null;
  isGenerating: boolean;
  chatItems: ChatItem[];
  spaceRecord: Record<string, Space | null>;
}

type PageAction =
  | { type: "SET_SESSION_ID"; payload: string | null }
  | { type: "SET_IS_GENERATING"; payload: boolean }
  | { type: "SET_CHAT_ITEMS"; payload: ChatItem[] }
  | { type: "ADD_SPACE"; payload: { id: string; value: Space | null } };

const initialState: PageState = {
  language: {
    code: getBrowserLanguageCode(),
    name: getBrowserLanguageName(),
  },
  sessionId: null,
  isGenerating: false,
  chatItems: [],
  spaceRecord: {},
};

function pageReducer(state: PageState, action: PageAction): PageState {
  switch (action.type) {
    case "SET_SESSION_ID":
      return { ...state, sessionId: action.payload };
    case "SET_IS_GENERATING":
      return { ...state, isGenerating: action.payload };
    case "SET_CHAT_ITEMS":
      return { ...state, chatItems: action.payload };
    case "ADD_SPACE":
      return {
        ...state,
        spaceRecord: {
          ...state.spaceRecord,
          [action.payload.id]: action.payload.value,
        },
      };
    default:
      return state;
  }
}

interface PageContextInterface {
  state: PageState;
  dispatch: React.Dispatch<PageAction>;
}

const PageContext = createContext<PageContextInterface>(undefined as any);

export const PageContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(pageReducer, initialState);

  return (
    <PageContext.Provider value={{ state, dispatch }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  const context = useContext(PageContext);

  if (!context) {
    throw new Error("usePageContext must be used within a Context boundary");
  }

  return context;
};
