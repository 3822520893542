import React from "react";

import styles from "../style/UserMessageChatItem.module.scss";
import { UserMessage } from "../types";

type UserMessageChatItemProps = {
  message: UserMessage;
};

const UserMessageChatItem: React.FC<UserMessageChatItemProps> = (props) => {
  return (
    <div className={styles.row}>
      <div className={styles.bubble}>
        <p className={styles.text}>{props.message.content}</p>
      </div>
    </div>
  );
};

export default UserMessageChatItem;
