import React from "react";
import DayTripCircleIcon from "../asset/daytrip-circle-icon.png";
import BotTextMessage from "./BotTextMessage";
import BotSpaceListMessage from "./BotSpaceListMessage";

import styles from "../style/BotMessageGroupChatItem.module.scss";
import { BotMessage } from "../types";
import QuestionSuggestionMessage from "./QuestionSuggestionMessage";

type BotMessageGroupChatItemProps = {
  messages: BotMessage[];

  isProcessing?: boolean;
};

const BotMessageGroupChatItem: React.FC<BotMessageGroupChatItemProps> = (
  props,
) => {
  return (
    <div className={styles.container}>
      <img className={styles.avatarImg} src={DayTripCircleIcon} alt="" />
      <div className={styles.bodyDiv}>
        <label className={styles.botNameText}>DayTrip</label>
        <div className={styles.contentDiv}>
          {props.messages.map((message, index) =>
            message.type === "text" ? (
              <BotTextMessage key={index} message={message} />
            ) : message.type === "space-list" ? (
              <BotSpaceListMessage key={index} message={message} />
            ) : message.type === "question-suggestions" ? (
              <QuestionSuggestionMessage key={index} message={message} />
            ) : null,
          )}
        </div>
      </div>
    </div>
  );
};

export default BotMessageGroupChatItem;
