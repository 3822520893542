import axios, { AxiosInstance, AxiosResponse, Method } from "axios";
import qs from "qs";
import { getBrowserLanguageCode } from "../../util";

export class Client {
  private static _client: AxiosInstance | null = null;
  private static _tokenGetter: (() => Promise<string>) | null = null;

  private static get client(): AxiosInstance {
    if (this._client === null)
      this._client = axios.create({
        baseURL: "https://apiv2.daytripapp.org",
        responseType: "json",
        timeout: 10000,
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            allowDots: true,
          });
        },
      });
    return this._client;
  }

  static setTokenGetter(value: typeof Client._tokenGetter) {
    this._tokenGetter = value;
  }

  static async request<
    Q extends DayTripApiService.Request<any, any>,
    S extends DayTripApiService.Response<any>,
  >(method: Method, path: string, request: Q): Promise<S> {
    let status, data;

    try {
      const token = await Client._tokenGetter?.();
      const res = await this.client.request<
        Q["data"],
        AxiosResponse<
          | { result: "SUCCESS"; data: S }
          | { result: "FAIL"; errorCode: string; message: string }
        >
      >({
        method,
        url: path,
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "application/json",
          "accept-language": getBrowserLanguageCode(),
        },
        params: request.params,
        data: method === "GET" ? undefined : request.data,
        signal: request.options?.abortController?.signal,
      });

      status = res.status;
      data = res.data;
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error(`AUTHENTICATION_FAIL::${method}::${path}`);
        } else if (error.response.status === 403) {
          throw new Error(`NOT_ENOUGH_PERMISSION::${method}::${path}`);
        } else {
          throw new Error(
            `NON_200_RESPONSE::${method}::${path}::${error.response.status}`,
          );
        }
      } else {
        throw new Error(`UNKNOWN::${method}::${path}`);
      }
    }

    if (status !== 200) {
      throw new Error("NON_200_RESPONSE");
    }

    if (data.result === "FAIL") {
      throw new Error(`[${data.errorCode}] ${data.message}`);
    }

    return data.data;
  }
}
