import React from "react";
import { useNavigate } from "react-router-dom";

interface DaylogItemProps {
  id: string;
  imageUrl: string;
  spaceName: string;
  areas: string;
  username: string;
}

const DaylogItem: React.FC<DaylogItemProps> = (props) => {
  const navigate = useNavigate();

  return (
    <div
      className="position-relative w-100 rounded overflow-hidden"
      style={{ aspectRatio: 0.7, cursor: "pointer" }}
      onClick={() => navigate(`/daylog/${props.id}`)}
    >
      <img
        className="w-100 h-100 object-fit-cover"
        src={props.imageUrl}
        alt=""
      />
      <div className="position-absolute w-100 h-100 top-0 left-0 d-flex flex-column justify-content-end">
        <div className="pt-5 p-3 vertical-gradient-overlay">
          <div className="mb-3">
            <span
              className="bg-white rounded-1 py-1 px-2 fw-bold"
              style={{ fontSize: 11 }}
            >
              {props.username}
            </span>
          </div>
          <h6 className="text-white mb-1">
            <strong>{props.spaceName}</strong>
          </h6>
          <p className="text-white mb-0">
            <small>{props.areas}</small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DaylogItem;
