import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User } from "@firebase/auth";
import { setAnonymous, setAuthenticated } from "../state/auth-slice";
import DayTripApiClient from "../client/DayTripApiClient";

export const handleAuthStateChange =
  (user: User | null): ThunkAction<Promise<void>, RootState, void, any> =>
  async (dispatch, getState) => {
    if (user === null) {
      DayTripApiClient.setTokenGetter(null);
      dispatch(setAnonymous());
      return;
    }

    DayTripApiClient.setTokenGetter(user.getIdToken.bind(user));

    try {
      const response = await DayTripApiClient.route.getProfileDetail();
      dispatch(
        setAuthenticated({
          id: response.id,
          username: response.username,
          imageUrl: response.photoUrl,
        }),
      );
    } catch (e) {
      if (!(e instanceof Error)) {
        return;
      }

      if (e.message.startsWith("NON_200_RESPONSE")) {
        const [, , , code] = e.message.split("::");
        if (code === "404") {
          const response = await DayTripApiClient.route.createProfile();
          dispatch(
            setAuthenticated({
              id: response.id,
              username: response.username,
              imageUrl: response.photoUrl,
            }),
          );
        }
      }
    }
  };
