import React from "react";

type MultilineTextProps = {
  value: string;
};

const MultilineText: React.FC<MultilineTextProps> = (props) => {
  return (
    <>
      {props.value.split("\n").map((v, i) => (
        <span key={i}>
          {v}
          <br />
        </span>
      ))}
    </>
  );
};

export default MultilineText;
