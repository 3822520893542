import { Client } from "../client";

type Request = DayTripApiService.Request;

type Response = DayTripApiService.Response<{
  address: string;
  country: string;
  city: string;
  regionName: string;
  latitude: string;
  longitude: string;
}>;

export const getUserLocationMetadata = (): Promise<Response> =>
  Client.request<Request, Response>(
    "GET",
    `/api/v1/users:locationMetadata`,
    {},
  );
