import React, { useCallback, useEffect, useMemo, useState } from "react";

import LoadMoreButton from "../component/LoadMoreButton";
import DayTripApiClient, {
  DayTripApiResponse,
} from "../client/DayTripApiClient";
import { useParams } from "react-router-dom";
import DaylogGridList from "../component/DaylogGridList";

type Data = DayTripApiResponse<typeof DayTripApiClient.route.getUserDaylog>;

const UserDetailDaylogPage: React.FC = () => {
  const { user_id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Data | null>(null);

  const load = useCallback(async () => {
    if (user_id === undefined) return;

    try {
      setIsLoading(true);
      setData(null);

      const response = await DayTripApiClient.route.getUserDaylog(user_id);
      setData(response);
    } catch (e) {
      if (e instanceof Error) alert(e.message);
      else alert("Unknown error");
    } finally {
      setIsLoading(false);
    }
  }, [user_id]);

  const onLoadMore = useCallback(async () => {
    if (user_id === undefined || data === null) return;

    try {
      setIsLoading(true);

      const response = await DayTripApiClient.route.getUserDaylog(
        user_id,
        data.next,
      );
      setData({
        ...data,
        next: response.next,
        page: [...data.page, ...response.page],
      });
    } catch (e) {
      if (e instanceof Error) alert(e.message);
      else alert("Unknown error");
    } finally {
      setIsLoading(false);
    }
  }, [user_id, data]);

  useEffect(() => {
    load().then();
  }, []);

  const daylogs = useMemo(() => {
    if (data === null) return [];

    return data.page.map((item) => ({
      id: item.id,
      imageUrl: item.images[0].url,
      spaceName: item.space.name,
      username: item.ownerUser.username,
      areas: item.space.tag.areas.map((v) => v.name).join(", "),
    }));
  }, [data]);

  return (
    <div className="d-grid grid-column gap-5">
      {!isLoading && daylogs.length === 0 && (
        <p className="text-center text-secondary">No Daylogs, yet.</p>
      )}

      {daylogs.length > 0 && <DaylogGridList columns={3} daylogs={daylogs} />}

      {isLoading && (
        <div className="d-flex justify-content-center text-body-tertiary">
          <div className="spinner-border spinner-border-sm" />
        </div>
      )}

      {daylogs.length > 0 && (
        <LoadMoreButton
          current={daylogs.length}
          total={data?.totalElementCount ?? 0}
          hasNext={data !== null && data.next !== ""}
          onLoadMore={onLoadMore}
        />
      )}
    </div>
  );
};

export default UserDetailDaylogPage;
