import React from "react";

type Space = {
  id: string;
  name: string;
  imageUrl: string;
  address: string;
};

type SpaceItemProps = {
  value: Space;
};

const SpaceItem: React.FC<SpaceItemProps> = (props) => {
  return (
    <div className="d-flex flex-row align-items-center">
      <img
        src={props.value.imageUrl}
        alt=""
        className="rounded rounded-1 me-3"
        style={{ height: 60, aspectRatio: 0.7 }}
      />
      <div className="text-start">
        <p className="mb-0">{props.value.name}</p>
        <p className="small text-body-secondary mb-0">{props.value.address}</p>
      </div>
    </div>
  );
};

export default SpaceItem;
