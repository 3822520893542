import { Client } from "../client";

type Request = DayTripApiService.Request<
  void,
  {
    imageResourceMetaIds: string[];
    description: string;
    spaceId: string;
    visitedDate: string;
    topicIds: string[];
    isPublic: boolean;
    adType: "NONE";
  }
>;

type Response = DayTripApiService.Response<{ id: string }>;

export const createDaylog = (req: Request): Promise<Response> =>
  Client.request<Request, Response>("POST", `/api/v1/content/daylog`, req);
