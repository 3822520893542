import { Client } from "../client";

type Request = DayTripApiService.Request;

type Response = DayTripApiService.Response<{
  id: string;
  name: string;
  origin: string;
  location: {
    coordinate: {
      lat: number;
      lng: number;
    };
    address: string;
    distance: number;
  };
  tag: {
    areas: {
      id: string;
      type: string;
      name: string;
    }[];
    spaceTypes: {
      id: string;
      name: string;
    }[];
    placeTypes: {
      type: string;
      categoryType: string;
      name: string;
    }[];
    primaryPlaceType: {
      type: string;
      categoryType: string;
      name: string;
    } | null;
    placeId: string;
    badges: {
      type: "DOG_FRIENDLY";
      name: string;
    }[];
  };
  option: {
    isCurated: true;
    isSaved: true;
    hasVisited: true;
    isOperationalGooglePlacePOI: true;
  };
  information: {
    openingHours: [string];
    operationalStatus: string;
    operationalStatusName: string;
    operationalDescription: string;
    website: string;
    phoneNumber: string;
    currencySymbol: string;
    priceLevel: number;
    isOpen: true;
  };
  summary: {
    saveUserCount: number;
    popularityScore: number;
  };
  images: { id: string; sourceType: string; url: string }[];
  createdAt: string;
  updatedAt: string;
}>;

export const getSpaceDetail = (spaceId: string): Promise<Response> =>
  Client.request<Request, Response>(
    "GET",
    `/api/v1/content/space/${spaceId}`,
    {},
  );
