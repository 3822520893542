import React, { useCallback, useEffect } from "react";
import LogoSvg from "../daytrip.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../store";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { authService } from "../client/firebase";

const SignInPage: React.FC = () => {
  const authState = useAppSelector((state) => state.auth.state);
  const navigate = useNavigate();
  const location = useLocation();

  const onGoogleSignIn = useCallback(async () => {
    if (authState !== "ANONYMOUS") return;

    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      await signInWithPopup(authService, provider);
    } catch {
      // ignore
    }
  }, [authState]);

  useEffect(() => {
    if (authState === "AUTHENTICATED") {
      const from = location.state?.from?.pathname || "/";
      navigate(from, { replace: true });
    }
  }, [authState]);

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ width: "100vw", height: "100vh" }}
    >
      <Link to="/">
        <img className="mb-5" src={LogoSvg} alt="" style={{ width: 240 }} />
      </Link>
      <div className="d-flex flex-row gap-4">
        <div
          className="rounded-circle d-flex align-items-center justify-content-center h5 text-white"
          style={{
            width: 48,
            height: 48,
            background: "black",
            cursor: "pointer",
          }}
          onClick={onGoogleSignIn}
        >
          <i className="bi bi-google"></i>
        </div>
      </div>

      <div
        className="position-absolute bottom-0 py-5 text-body-secondary"
        style={{ fontSize: 12 }}
      >
        &#169; OurSpace Inc. All Rights Reserved
      </div>
    </div>
  );
};

export default SignInPage;
