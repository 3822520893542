import { Client } from "../client";

type Request = DayTripApiService.Request;

type Response = DayTripApiService.Response<{
  id: string;
  code: string;
  username: string;
  photoUrl: string;
  intro: {
    checksum: string;
    value: string;
    language: string;
    isTranslated: boolean;
  };
  option: {
    isBusiness: boolean;
    isCelebrity: boolean;
    isCurator: boolean;
    isPrivate: boolean;
    hasDayTripBadge: boolean;
    isContentsRecommended: boolean;
    curatorApplicationStatus: string;
    monetizeApplicationStatus: string;
  };
  meta: {
    isFollowing: boolean;
    isFollowRequested: boolean;
    hasNewPost: boolean;
  };
  summary: {
    daylogCount: number;
    curationCount: number;
    checkinCount: number;
    followerUserCount: number;
    followingUserCount: number;
  };
  createdAt: string;
  updatedAt: string;
  info: {
    countryAreaId: string;
    email: string;
    birthdate: string;
    gender: string;
    instagramUsername: string;
    externalLinks: {
      title: {
        checksum: string;
        value: string;
        language: string;
        isTranslated: boolean;
      };
      url: string;
    }[];
  };
}>;

export const getUserDetail = (userId: string): Promise<Response> =>
  Client.request<Request, Response>(
    "GET",
    `/api/v2/users/${userId}:bySimple`,
    {},
  );
