import React, { useCallback, useEffect, useRef, useState } from "react";

import LogoImage from "../daytrip.svg";
import { Link, Outlet, ScrollRestoration, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { signOut } from "../operation/sign-out";
import _ from "lodash";
import clsx from "clsx";

const BasePage: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-white position-sticky top-0 z-3 mt-5">
        <div className="container py-3">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <Link className="px-3" to="/">
              <img src={LogoImage} alt="" height={46} />
            </Link>
            <div className="d-flex flex-row align-items-center gap-3">
              {auth.state === "LOADING" && (
                <div className="spinner-border spinner-border-sm mx-3"></div>
              )}
              {auth.state === "ANONYMOUS" && (
                <Link className="btn" to="/sign-in">
                  Sign in
                </Link>
              )}
              {auth.state === "AUTHENTICATED" && (
                <>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/user/${auth.user.id}`)}
                  >
                    <img
                      className="rounded-circle me-2"
                      src={auth.user.imageUrl}
                      alt=""
                      width={32}
                      height={32}
                    />
                    <h6 className="mb-0 text-body-emphasis text-decoration-none">
                      {auth.user.username}
                    </h6>
                  </div>
                  <button className="btn" onClick={() => dispatch(signOut())}>
                    Sign out
                  </button>
                </>
              )}
              <CreateButton />
            </div>
          </div>
        </div>
      </div>
      <div className="py-5" style={{ minHeight: "75vh" }}>
        <Outlet />
      </div>
      <div className="bg-dark">
        <div
          className="container text-white"
          style={{ padding: "5rem 0 5rem 0" }}
        >
          <div className="row">
            <div className="col-4">
              <h4 className="mb-3">
                Download DayTrip
                <br />
                to discover hidden gems around you.
              </h4>
              <div className="d-flex flex-row gap-3">
                <a
                  className="btn btn-outline-light py-1 px-3 d-flex flex-row align-items-center"
                  href="https://play.google.com/store/apps/details?id=com.ourspaceapp.daytrip&hl=en&pli=1"
                  style={{ width: 180 }}
                >
                  <i className="bi bi-google-play" style={{ fontSize: 28 }}></i>
                  <div className="text-start lh-1 ms-2 mb-1">
                    <span style={{ fontSize: 12 }}>Get it on</span>
                    <br />
                    <span style={{ fontSize: 20 }}>Google Play</span>
                  </div>
                </a>
                <a
                  className="btn btn-outline-light py-1 px-3 d-flex flex-row align-items-center"
                  href="https://apps.apple.com/us/app/daytrip-curated-travel-guide/id1516579050"
                  style={{ width: 180 }}
                >
                  <i className="bi bi-apple" style={{ fontSize: 28 }}></i>
                  <div className="text-start lh-1 ms-2 mb-1">
                    <span style={{ fontSize: 12 }}>Download on the</span>
                    <br />
                    <span style={{ fontSize: 20 }}>App Store</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-7 offset-1">
              <a
                className="btn text-white mb-5 px-0"
                href="https://www.daytrip.io/"
              >
                DayTrip Business Website
                <i className="bi bi-box-arrow-up-right ms-2"></i>
              </a>
              <div className="d-flex flex-row gap-5 mb-5">
                <a
                  className="btn text-white px-0"
                  href="https://www.instagram.com/daytrip.nyc"
                >
                  <i className="bi bi-instagram me-2"></i>
                  daytrip.nyc
                </a>
                <a
                  className="btn text-white px-0"
                  href="https://www.instagram.com/daytripkorea"
                >
                  <i className="bi bi-instagram me-2"></i>
                  daytripkorea
                </a>
                <a
                  className="btn text-white px-0"
                  href="https://www.instagram.com/daytrip.asia"
                >
                  <i className="bi bi-instagram me-2"></i>
                  daytrip.asia
                </a>
                <a
                  className="btn text-white px-0"
                  href="https://www.linkedin.com/company/ourspace"
                >
                  <i className="bi bi-instagram me-2"></i>
                  DayTrip Official
                </a>
              </div>

              <div className="mb-5">
                <div className="row mb-2">
                  <div className="col-3 text-white-50">New York Office</div>
                  <div className="col-9">368 9th Ave, New York, NY 10001</div>
                </div>
                <div className="row">
                  <div className="col-3 text-white-50">Seoul Office</div>
                  <div className="col-9">
                    17, Seobinggo-ro, Yongsan-gu, Seoul, Korea
                  </div>
                </div>
              </div>

              <div className="text-white-50" style={{ fontSize: 12 }}>
                &#169; OurSpace Inc. All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollRestoration />
    </div>
  );
};

export default BasePage;

const CreateButton: React.FC = () => {
  const componentId = useRef(_.uniqueId("home-create-button"));

  const [isOpened, setIsOpened] = useState(false);

  const onClickEvent = useCallback((e: MouseEvent) => {
    const element = document.getElementById(componentId.current);
    if (element === null) return;

    if (!element.contains(e.target as any)) {
      setIsOpened(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", onClickEvent);
    return () => window.removeEventListener("click", onClickEvent);
  }, []);

  return (
    <div id={componentId.current} className="position-relative">
      <button
        type="button"
        className="btn btn-dark"
        onClick={() => setIsOpened(!isOpened)}
      >
        Create
        <i className="bi bi-chevron-down ms-2"></i>
      </button>
      <div
        className={clsx(
          "position-absolute z-3 mt-2 end-0",
          !isOpened && "d-none",
        )}
      >
        <ul
          className="border border-light-subtle rounded shadow-sm d-flex flex-column gap-2 p-2 bg-white text-center"
          style={{ minWidth: 300 }}
        >
          <Link
            to="/new-curation"
            className="btn"
            onClick={() => setIsOpened(false)}
          >
            Create New Curation
          </Link>
          <Link
            to="/new-daylog"
            className="btn"
            onClick={() => setIsOpened(false)}
          >
            Create New Daylog
          </Link>
        </ul>
      </div>
    </div>
  );
};
