import React from "react";
import Markdown from "react-markdown";

import styles from "../style/BotTextMessage.module.scss";
import { BotMessage } from "../types";

type BotTextMessageProps = {
  message: Extract<BotMessage, { type: "text" }>;
};

const BotTextMessage: React.FC<BotTextMessageProps> = (props) => {
  return <Markdown className={styles.text}>{props.message.content}</Markdown>;
};

export default BotTextMessage;
