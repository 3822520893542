import React, { ChangeEvent, useCallback, useRef } from "react";
import SpaceSelector from "../component/SpaceSelector";
import SpaceItem from "../component/SpaceItem";
import DayTripApiClient from "../client/DayTripApiClient";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import _ from "lodash";

type Space = {
  id: string;
  name: string;
  imageUrl: string;
  address: string;
};

const NewDaylogPage: React.FC = () => {
  const [space, setSpace] = React.useState<Space | null>(null);
  const [images, setImages] = React.useState<File[]>([]);
  const [visitedDate, setVisitedDate] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [isCreating, setIsCreating] = React.useState(false);

  const navigate = useNavigate();

  const onCreate = useCallback(async () => {
    if (space === null) {
      alert("Please select space.");
      return;
    }

    if (images.length === 0) {
      alert("Daylog must contain at least one image.");
      return;
    }

    if (visitedDate === "") {
      alert("Please select valid visited Date");
      return;
    }

    const uploadImage = async (file: File): Promise<string> => {
      const filename = _.uniqueId("daylog_image_") + Date.now();

      const preSignedUrl = await DayTripApiClient.route.createPreSignedUrl({
        data: { type: "CONTENT_IMAGE", filename: filename },
      });

      await axios.put(preSignedUrl, await file.arrayBuffer());

      const result = await DayTripApiClient.route.createResourceMeta({
        data: {
          filename: filename,
          name: filename,
          type: "CONTENT_IMAGE",
          fileExtension: file.type,
        },
      });

      return result.id;
    };

    try {
      setIsCreating(true);

      const imageResourceMetaIds = await Promise.all(images.map(uploadImage));
      const result = await DayTripApiClient.route.createDaylog({
        data: {
          imageResourceMetaIds: imageResourceMetaIds,
          description: description,
          spaceId: space.id,
          visitedDate: visitedDate,
          topicIds: [],
          isPublic: true,
          adType: "NONE",
        },
      });
      navigate(`/daylog/${result.id}`);
    } catch (e) {
      if (e instanceof Error) alert(e.message);
      else alert("Unknown error");
    } finally {
      setIsCreating(false);
    }
  }, [space, images, visitedDate, description]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-6 offset-3 d-flex flex-column gap-5">
          <h4 className="mb-0">Create New Daylog</h4>

          <ImageUploader value={images} onChange={setImages} />

          {space === null ? (
            <SpaceSelector onSelect={setSpace} />
          ) : (
            <div className="d-flex flex-row align-items-center">
              <div className="flex-fill me-3">
                <SpaceItem value={space} />
              </div>
              <button
                className="btn btn-sm btn-outline-danger"
                onClick={() => setSpace(null)}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          )}

          <input
            type="date"
            className="form-control"
            value={visitedDate}
            onChange={(e) => setVisitedDate(e.target.value)}
          />

          <textarea
            className="form-control"
            rows={10}
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <button
            className="btn btn-dark"
            onClick={onCreate}
            disabled={isCreating}
          >
            {isCreating ? (
              <div className="spinner-border spinner-border-sm" />
            ) : (
              "Create"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewDaylogPage;

type ImageUploaderProps = {
  value: File[];
  onChange: (value: File[]) => void;
};

const ImageUploader: React.FC<ImageUploaderProps> = (props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = event.target.files;
      if (!selectedFiles) return;

      const filesArray = Array.from(selectedFiles);
      const imageFiles = filesArray.filter((file) =>
        file.type.startsWith("image/"),
      );

      if (imageFiles.length > 0) {
        props.onChange([...props.value, ...imageFiles]);
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    },
    [props.value, props.onChange],
  );

  return (
    <div>
      <div className="overflow-x-scroll mb-2">
        <div className="d-flex flex-row gap-2">
          {props.value.map((value, index) => (
            <img
              key={index}
              className="rounded"
              src={URL.createObjectURL(value)}
              alt=""
              style={{ height: 260, width: 182, objectFit: "cover" }}
            />
          ))}
          <div
            className="bg-secondary-subtle rounded d-flex justify-content-center align-items-center flex-shrink-0"
            style={{ height: 260, width: 182, objectFit: "cover" }}
          >
            <i
              className="bi bi-plus-circle text-white"
              style={{ fontSize: 40, cursor: "pointer" }}
              onClick={() => fileInputRef.current?.click()}
            ></i>
          </div>
        </div>
      </div>
      <input
        className="d-none"
        type="file"
        accept="image/*"
        multiple
        ref={fileInputRef}
        onChange={onFileChange}
      />
    </div>
  );
};
