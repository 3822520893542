import { Client } from "../client";

type Request = DayTripApiService.Request<{ next?: string }>;

type Response = DayTripApiService.Response<{
  next: string;
  model: string;
  page: {
    id: string;
    images: { id: string; url: string }[];
    video: { id: string; url: string };
    description: {
      checksum: string;
      value: string;
      originalValue: string;
      language: string;
      originalLanguage: string;
      isTranslated: boolean;
    };
    shortDescription: {
      checksum: string;
      value: string;
      language: string;
      isTranslated: boolean;
    };
    premiumIndex: number;
    option: {
      isPrivate: boolean;
      isBlocked: boolean;
      isCurated: boolean;
      isLike: boolean;
      sponsorType: string;
    };
    summary: {
      clapCount: number;
      clappedByCurrentUserCount: number;
      likeCount: number;
      exposureCount: number;
      readCount: number;
    };
    meta: { originalLanguage: string };
    visitedAt: string;
    createdAt: string;
    updatedAt: string;
    space: {
      id: string;
      name: string;
      origin: string;
      location: {
        coordinate: { lat: number; lng: number };
        address: string;
        distance: number;
      };
      tag: {
        regions: { id: string; name: string }[];
        areas: { id: string; type: string; name: string }[];
        spaceTypes: { id: string; name: string }[];
        placeTypes: { type: string; categoryType: string; name: string }[];
        primaryPlaceType: { type: string; categoryType: string; name: string };
        placeId: string;
        badges: { type: string; name: string }[];
      };
      option: {
        isCurated: boolean;
        isSaved: boolean;
        hasVisited: boolean;
        isOperationalGooglePlacePOI: boolean;
      };
      information: {
        openingHours: string[];
        operationalStatus: string;
        operationalStatusName: string;
        operationalDescription: string;
        website: string;
        phoneNumber: string;
        currencySymbol: string;
        priceLevel: number;
        isOpen: boolean;
      };
      summary: {
        saveUserCount: number;
        popularityScore: number;
      };
      createdAt: string;
      updatedAt: string;
    };
    ownerUser: {
      id: string;
      code: string;
      username: string;
      photoUrl: string;
      intro: {
        checksum: string;
        value: string;
        language: string;
        isTranslated: boolean;
      };
      option: {
        isBusiness: boolean;
        isCelebrity: boolean;
        isCurator: boolean;
        isPrivate: boolean;
        hasDayTripBadge: boolean;
        isContentsRecommended: boolean;
        curatorApplicationStatus: string;
        monetizeApplicationStatus: string;
      };
      meta: {
        isFollowing: boolean;
        isFollowRequested: boolean;
        hasNewPost: boolean;
      };
      summary: {
        daylogCount: number;
        curationCount: number;
        checkinCount: number;
        followerUserCount: number;
        followingUserCount: number;
      };
      createdAt: string;
      updatedAt: string;
    };
  }[];
  totalElementCount: number;
}>;

export const getUserDaylog = (
  userId: string,
  next?: string,
): Promise<Response> =>
  Client.request<Request, Response>(
    "GET",
    `/api/v1/content/daylog/${userId}:byUserSimple`,
    { params: { next: next } },
  );
