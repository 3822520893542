import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setLoading } from "../state/auth-slice";
import { authService } from "../client/firebase";

export const signOut =
  (): ThunkAction<Promise<void>, RootState, void, any> =>
  async (dispatch, getState) => {
    if (getState().auth.state !== "AUTHENTICATED") return;

    dispatch(setLoading());

    await authService.signOut();
  };
