import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

interface CurationItemProps {
  id: string;
  imageUrl: string;
  title: string;
  username: string;
}

const CurationItem: React.FC<CurationItemProps> = (props) => {
  const navigate = useNavigate();

  const splitTitle = useMemo(() => {
    if (props.title.length < 16) return props.title;

    const tokens = props.title.split(" ");
    return (
      <>
        {tokens.slice(0, 3).join(" ")}
        <br />
        {tokens.slice(3).join(" ")}
      </>
    );
  }, [props.title]);

  return (
    <div
      className="position-relative w-100 rounded overflow-hidden"
      style={{ aspectRatio: 0.7, cursor: "pointer" }}
      onClick={() => navigate(`/curation/${props.id}`)}
    >
      <img
        className="w-100 h-100 object-fit-cover"
        src={props.imageUrl}
        alt=""
      />
      <div className="position-absolute w-100 h-100 top-0 left-0 d-flex flex-column justify-content-end">
        <div className="pt-5 p-3 vertical-gradient-overlay">
          <div className="mb-3">
            <span
              className="bg-white rounded-1 py-1 px-2 fw-bold"
              style={{ fontSize: 11 }}
            >
              {props.username}
            </span>
          </div>
          <h5 className="text-white lh-base">
            <strong>{splitTitle}</strong>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default CurationItem;
