import React from "react";
import styles from "../style/PresetQuestions.module.scss";
import { PresetQuestion } from "../types";

type PresetQuestionsProps = {
  questions: PresetQuestion[];
  onSelect: (question: string) => void;
};

const PresetQuestions: React.FC<PresetQuestionsProps> = (props) => {
  return (
    <div className={styles.container}>
      <div className="d-flex flex-row" style={{ gap: "8px" }}>
        <div className={styles.itemSpacer} />
        {props.questions.map((question, index) => (
          <div
            key={index}
            className={styles.itemContainer}
            onClick={() => props.onSelect(question.content)}
          >
            <label className={styles.titleText}>{question.title}</label>
            <label className={styles.contentText}>{question.content}</label>
          </div>
        ))}
        <div className={styles.itemSpacer} />
      </div>
    </div>
  );
};

export default PresetQuestions;
