import { Client } from "../client";

type Request = DayTripApiService.Request<
  void,
  {
    query: string;
    hasDaylog: boolean;
    isCurated: boolean;
    limit: number;
  }
>;

type Response = DayTripApiService.Response<
  {
    id: string;
    highlightName: string;
    name: string;
    locationDescription: string;
    primaryPlaceType: {
      type: string;
      categoryType: string;
      name: string;
    };
    placeId: string;
    images: { id: string; url: string }[];
    distance: 0;
  }[]
>;

export const getSpaceAutoComplete = (query: string): Promise<Response> =>
  Client.request<Request, Response>(
    "POST",
    `/api/v1/content/space:autocompleteByName`,
    {
      data: {
        query: query,
        hasDaylog: true,
        isCurated: false,
        limit: 5,
      },
    },
  );
