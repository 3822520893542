import { Client } from "../client";

type Request = DayTripApiService.Request<
  void,
  { type: "CONTENT_IMAGE"; filename: string }
>;

type Response = DayTripApiService.Response<string>;

export const createPreSignedUrl = (req: Request): Promise<Response> =>
  Client.request<Request, Response>(
    "POST",
    `/api/v1/resource/bucket:presignedUrl`,
    req,
  );
