import React, { useCallback, useEffect, useRef } from "react";
import _ from "lodash";
import DayTripApiClient from "../client/DayTripApiClient";
import SpaceItem from "./SpaceItem";

type Space = {
  id: string;
  name: string;
  imageUrl: string;
  address: string;
};

type SpaceSearchBoxProps = {
  onSelect: (value: Space) => void;
};

const SpaceSelector: React.FC<SpaceSearchBoxProps> = (props) => {
  const componentId = useRef(_.uniqueId("space-search-input"));

  const [query, setQuery] = React.useState("");
  const [isOpened, setIsOpened] = React.useState(false);
  const [debouncedValue, setDebouncedValue] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState<Space[]>([]);

  const load = useCallback(async (q: string) => {
    if (q === "") {
      setData([]);
    }

    try {
      const result = await DayTripApiClient.route.getSpaceAutoComplete(q);
      setData(
        result.map((v) => ({
          id: v.id,
          name: v.name,
          imageUrl: v.images[0].url,
          address: v.locationDescription,
        })),
      );
    } catch {
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const handler = setTimeout(() => {
      setDebouncedValue(query);
    }, 500);

    return () => clearTimeout(handler);
  }, [query]);

  useEffect(() => {
    load(debouncedValue).then();
  }, [debouncedValue]);

  const onClickEvent = useCallback((e: MouseEvent) => {
    const element = document.getElementById(componentId.current);
    if (element === null) return;

    if (!element.contains(e.target as any)) {
      setIsOpened(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", onClickEvent);
    return () => window.removeEventListener("click", onClickEvent);
  }, []);

  return (
    <div className="position-relative" id={componentId.current}>
      <input
        className="form-control"
        placeholder="Search for space..."
        onFocus={() => setIsOpened(true)}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {isOpened && query.length > 0 && (
        <div className="position-absolute w-100 mt-2 border border-light-subtle rounded shadow-sm d-flex flex-column gap-2 p-2 bg-white z-3">
          {isLoading ? (
            <div className="d-flex justify-content-center py-3 text-body-tertiary">
              <div className="spinner-border spinner-border-sm" />
            </div>
          ) : data.length === 0 ? (
            <div className="d-flex justify-content-center py-3 text-body-tertiary">
              No Search Result
            </div>
          ) : (
            data.map((value) => (
              <div
                key={value.id}
                className="btn"
                onClick={() => {
                  setIsOpened(false);
                  props.onSelect(value);
                }}
              >
                <SpaceItem value={value} />
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SpaceSelector;
