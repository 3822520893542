import { Client } from "../client";

type Request = DayTripApiService.Request;

type Response = DayTripApiService.Response<{
  id: string;
  code: string;
  username: string;
  photoUrl: string;
  intro: {
    checksum: string;
    value: string;
    language: string;
    isTranslated: true;
  };
  option: {
    isBusiness: true;
    isCelebrity: true;
    isCurator: true;
    isPrivate: true;
    hasDayTripBadge: true;
    isContentsRecommended: true;
    curatorApplicationStatus: string;
    monetizeApplicationStatus: string;
  };
  meta: {
    isFollowing: true;
    isFollowRequested: true;
    hasNewPost: true;
  };
  summary: {
    daylogCount: number;
    curationCount: number;
    checkinCount: number;
    followerUserCount: number;
    followingUserCount: number;
  };
  createdAt: string;
  updatedAt: string;
  info: {
    countryAreaId: string;
    email: string;
    birthdate: string;
    gender: string;
    instagramUsername: string;
    externalLinks: [
      {
        title: {
          checksum: string;
          value: string;
          language: string;
          isTranslated: true;
        };
        url: string;
      },
    ];
  };
}>;

export const getProfileDetail = (): Promise<Response> =>
  Client.request<Request, Response>("GET", `/api/v2/users:detail`, {});
