import React from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

const MainPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const category =
    location.pathname === "/curation"
      ? "CURATION"
      : location.pathname === "/daylog"
        ? "DAYLOG"
        : "CURATION";

  return (
    <div className="container">
      <div
        className="d-flex flex-row gap-3 mb-4"
        style={{ marginLeft: "-1rem" }}
      >
        <div
          className={clsx(
            "h5",
            "p-3",
            "mb-0",
            category === "CURATION" ? "text-black" : "text-body-tertiary",
          )}
          style={{ cursor: category === "CURATION" ? "default" : "pointer" }}
          onClick={() => category !== "CURATION" && navigate("/curation")}
        >
          Curation
        </div>
        <div
          className={clsx(
            "h5",
            "p-3",
            "mb-0",
            category === "DAYLOG" ? "text-black" : "text-body-tertiary",
          )}
          style={{ cursor: category === "DAYLOG" ? "default" : "pointer" }}
          onClick={() => category !== "DAYLOG" && navigate("/daylog")}
        >
          Daylog
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default MainPage;
