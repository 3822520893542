import React, { useEffect } from "react";
import MapSvg from "../asset/map.svg";
import GoogleLogoSvg from "../asset/google-logo.svg";
import styles from "../style/BotSpaceListMessage.module.scss";
import { BotMessage, Space } from "../types";
import Text from "../asset/text.json";
import { useSpace } from "../service/useSpace";
import clsx from "clsx";
import { useLanguage } from "../service/useLanguage";

type SpaceItemProps = {
  value: Space | null | undefined;
  reason: string;
};

const SpaceItem: React.FC<SpaceItemProps> = (props) => {
  const { code } = useLanguage();

  if (props.value === undefined) {
    return (
      <div className={clsx(styles.spaceDiv, styles.spaceEmptyDiv)}>
        <div
          className="spinner-border text-muted spinner-border-sm"
          role="status"
        />
      </div>
    );
  }

  if (props.value === null) {
    return (
      <div className={clsx(styles.spaceDiv, styles.spaceEmptyDiv)}>
        <p className="text-center text-muted">Failed loading</p>
      </div>
    );
  }

  return (
    <div className={styles.spaceDiv}>
      <div className="d-flex flex-row" style={{ padding: "12px 0" }}>
        <img src={props.value.image} alt="" className={styles.spaceImg} />
        <div className={styles.summaryDiv}>
          <p className={styles.spaceNameText}>{props.value.name}</p>
          <p className={styles.spaceSubText}>
            {props.value.category !== "" && <span>{props.value.category}</span>}
            {props.value.price !== "" && <span>{props.value.price}</span>}
          </p>
          <p className={styles.spaceSubText}>
            <span
              className={clsx(
                styles.spaceSubEmphasisText,
                props.value.openStatus === "OPEN" &&
                  styles.spaceSubActiveEmphasisText,
              )}
            >
              {props.value.openStatusDescription}
            </span>
            {props.value.operationDescription !== "" && (
              <span>{props.value.operationDescription}</span>
            )}
          </p>
        </div>
      </div>
      <p className={styles.reasoningText}>{props.reason}</p>
      <div className={styles.buttonGroupDiv}>
        <a
          className={styles.blackButton}
          target="_blank"
          href={`https://www.google.com/maps/place/?q=place_id:${props.value.googlePlaceId}`}
        >
          <img className={styles.buttonIcon} src={MapSvg} alt="" />
          <label className={styles.buttonText}>{Text[code].MAP}</label>
        </a>
        <a
          className={styles.outlineButton}
          target="_blank"
          href={`https://search.google.com/local/reviews?placeid=${props.value.googlePlaceId}`}
        >
          <img className={styles.buttonIcon} src={GoogleLogoSvg} alt="" />
          <label className={styles.buttonText}>{Text[code].REVIEW}</label>
        </a>
        {props.value.websiteUrl && (
          <a
            className={styles.outlineButton}
            target="_blank"
            href={props.value.websiteUrl}
          >
            <label className={styles.buttonText}>{Text[code].WEBSITE}</label>
          </a>
        )}
      </div>
    </div>
  );
};

type BotSpaceListMessageProps = {
  message: Extract<BotMessage, { type: "space-list" }>;
};

const BotSpaceListMessage: React.FC<BotSpaceListMessageProps> = (props) => {
  const { spaceRecord, loadSpace } = useSpace();

  useEffect(() => {
    for (const space of props.message.spaces) {
      loadSpace(space.id).then();
    }
  }, []);

  if (props.message.spaces.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      {props.message.spaces.map((space, index) => (
        <SpaceItem
          key={index}
          value={spaceRecord[space.id]}
          reason={space.reason}
        />
      ))}
    </div>
  );
};

export default BotSpaceListMessage;
