import { Client } from "../client";

type Request = DayTripApiService.Request<
  void,
  {
    title: string;
    description: string;
    coverImageUrl: string;
    contentBlocks: { title: string; description: string; daylogId: string }[];
    targetCommunities: {
      topicTagId: string;
      area: { id: string; scope: string };
      mode: string;
    }[];
    option: { isPrivate: boolean };
  }
>;

type Response = DayTripApiService.Response<{ id: string }>;

export const createCuration = (req: Request): Promise<Response> =>
  Client.request<Request, Response>("POST", `/api/v1/content/curation`, req);
