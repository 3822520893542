import React, { useEffect } from "react";
import { useAppDispatch } from "./store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { authService } from "./client/firebase";
import { handleAuthStateChange } from "./operation/handle-auth-state-change";

import BasePage from "./page/BasePage";
import MainPage from "./page/MainPage";
import CurationDetailPage from "./page/CurationDetailPage";
import DaylogDetailPage from "./page/DaylogDetailPage";
import SignInPage from "./page/SignInPage";
import AuthRequiredPage from "./AuthRequiredPage";
import NewDaylogPage from "./page/NewDaylogPage";
import UserDetailPage from "./page/UserDetailPage";
import UserDetailCurationPage from "./page/UserDetailCurationPage";
import UserDetailDaylogPage from "./page/UserDetailDaylogPage";
import NewCurationPage from "./page/NewCurationPage";
import MainCurationPage from "./page/MainCurationPage";
import MainDaylogPage from "./page/MainDaylogPage";
import ChatbotPage from "./page/ChatbotPage";

// prettier-ignore
const router = createBrowserRouter([
  {
    path: "/",
    element: <BasePage />,
    children: [
      {
        path: "",
        element: <MainPage />,
        children: [
          { path: "", element: <MainCurationPage /> },
          { path: "curation", element: <MainCurationPage /> },
          { path: "daylog", element: <MainDaylogPage /> },
        ]
      },
      {
        path: "curation/:curation_id",
        element: <CurationDetailPage />,
      },
      {
        path: "daylog/:daylog_id",
        element: <DaylogDetailPage />,
      },
      {
        path: "/new-curation",
        element: <AuthRequiredPage><NewCurationPage /></AuthRequiredPage>,
      },
      {
        path: "/new-daylog",
        element: <AuthRequiredPage><NewDaylogPage /></AuthRequiredPage>,
      },
      {
        path: "user",
        children: [
          {
            path: ":user_id",
            element: <UserDetailPage />,
            children: [
              { path: "", element: <UserDetailCurationPage /> },
              { path: "curation", element: <UserDetailCurationPage /> },
              { path: "daylog", element: <UserDetailDaylogPage /> },
            ]
          },
        ],
      },
    ],
    errorElement: <BasePage />,
  },
  {
    path: "/chatbot",
    element: <ChatbotPage />,
  },
  {
    path: "/sign-in",
    element: <SignInPage />,
  },
]);

const App: React.FC = () => {
  const setInnerHeight = () => {
    document.documentElement.style.setProperty(
      "--inner-height",
      `${window.innerHeight}px`,
    );
  };

  useEffect(() => {
    window.addEventListener("resize", setInnerHeight);
    setInnerHeight();
    return () => window.removeEventListener("resize", setInnerHeight);
  }, []);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(handleAuthStateChange(authService.currentUser));
    const unsubscribe = authService.onAuthStateChanged((user) => {
      dispatch(handleAuthStateChange(user));
    });

    return () => unsubscribe();
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
