import React, { useState, useCallback, useEffect, useRef } from "react";
import BottomBar from "./BottomBar";
import BotMessageGroupChatItem from "./BotMessageGroupChatItem";
import UserMessageChatItem from "./UserMessageChatItem";
import styles from "../style/Page.module.scss";
import { useChat } from "../service/useChat";
import Text from "../asset/text.json";
import PresetQuestions from "../asset/preset-questions.json";
import ArrowDownSvg from "../asset/arrow-down.svg";
import FeedbackSvg from "../asset/feedback.svg";
import PlusSvg from "../asset/plus.svg";
import { useLanguage } from "../service/useLanguage";

const PageLayout: React.FC = () => {
  const { sessionId, chatItems, send, isGenerating } = useChat();
  const { code } = useLanguage();
  const [canScroll, setCanScroll] = useState(false);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const chatPaneRef = useRef<HTMLDivElement>(null);

  const checkScroll = useCallback(() => {
    const element = scrollAreaRef.current;
    if (!element) return;

    const isAtBottom =
      Math.abs(
        element.scrollHeight - element.scrollTop - element.clientHeight,
      ) < 1;

    setCanScroll(!isAtBottom);
  }, []);

  const scrollToBottom = useCallback(() => {
    const element = scrollAreaRef.current;
    if (!element) return;

    element.scrollTo({
      top: element.scrollHeight,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const scrollElement = scrollAreaRef.current;
    const paneElement = chatPaneRef.current;
    if (!scrollElement || !paneElement) return;

    const resizeObserver = new ResizeObserver(() => {
      checkScroll();
    });

    resizeObserver.observe(paneElement);
    scrollElement.addEventListener("scroll", checkScroll);

    checkScroll();

    return () => {
      resizeObserver.disconnect();
      scrollElement.removeEventListener("scroll", checkScroll);
    };
  }, [checkScroll]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <a className={styles.headerButton} href="" target="_blank">
          <img src={PlusSvg} alt="" className={styles.headerButtonIcon} />
        </a>
        <a
          className={styles.headerButton}
          href="https://forms.gle/bMozw9EeEcRchXER9"
          target="_blank"
        >
          <img src={FeedbackSvg} alt="" className={styles.headerButtonIcon} />
        </a>
      </div>
      <div
        ref={scrollAreaRef}
        className={styles.chatScrollArea}
        onScroll={checkScroll}
      >
        <div ref={chatPaneRef} className={styles.chatPane}>
          <BotMessageGroupChatItem
            messages={[
              { id: "", type: "text", content: Text[code].DEFAULT_MESSAGE },
            ]}
          />

          {chatItems.map((item, index) =>
            item.role === "user" ? (
              <UserMessageChatItem key={index} message={item.message} />
            ) : item.role === "bot" ? (
              <BotMessageGroupChatItem key={index} messages={item.messages} />
            ) : null,
          )}
          <div style={{ height: "172px" }} />
        </div>
      </div>

      <div className={styles.bottomBar}>
        {canScroll && (
          <div className={styles.scrollBottomButtonDiv}>
            <button
              className={styles.scrollBottomButton}
              onClick={scrollToBottom}
            >
              <img
                src={ArrowDownSvg}
                alt=""
                className={styles.scrollBottomIcon}
              />
            </button>
          </div>
        )}
        <BottomBar
          onSubmit={send}
          presetQuestions={sessionId === null ? PresetQuestions[code] : null}
          isProcessing={isGenerating}
        />
      </div>
    </div>
  );
};

export default PageLayout;
