import React, { useCallback, useEffect, useState } from "react";

import MultilineText from "../component/MultilineText";
import { useNavigate, useParams } from "react-router-dom";
import DayTripApiClient from "../client/DayTripApiClient";
import clsx from "clsx";

type Data = {
  title: string;
  coverImageUrl: string;
  userImageUrl: string;
  userUsername: string;
  createdAt: string;
  userId: string;
  description: string;
  content: {
    title: string;
    description: string;
    spaceName: string;
    spaceType: string;
    spaceAreas: string;
    imageUrls: string[];
    daylogDescription: string;
  }[];
};

const CurationDetailPage: React.FC = () => {
  const navigate = useNavigate();
  let { curation_id: curationId } = useParams();

  const [data, setData] = React.useState<Data | null>(null);

  const load = useCallback(async () => {
    if (curationId === undefined) return;

    try {
      const apiData =
        await DayTripApiClient.route.getCurationDetail(curationId);
      setData({
        title: apiData.name,
        coverImageUrl: apiData.coverImage.url,
        userImageUrl: apiData.ownerUser.photoUrl,
        userUsername: apiData.ownerUser.username,
        createdAt: apiData.createdAt,
        userId: apiData.ownerUser.id,
        description: apiData.description,
        content: apiData.curationItems.map((v) => ({
          title: v.title,
          description: v.description,
          spaceName: v.daylog.space.name,
          spaceType: v.daylog.space.tag.primaryPlaceType.name,
          spaceAreas: v.daylog.space.tag.areas.map((v) => v.name).join(", "),
          imageUrls: v.daylog.images.map((v) => v.url),
          daylogDescription: v.daylog.description.value,
        })),
      });
    } catch (e) {
      if (e instanceof Error) alert(e.message);
      else alert("Unknown Error");
    }
  }, [curationId]);

  useEffect(() => {
    load().then();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-4">
          <div className="position-sticky pb-5" style={{ top: 100 }}>
            {/* Cover */}
            <div
              className="position-relative w-100 rounded overflow-hidden mb-3 bg-secondary-subtle"
              style={{ aspectRatio: 0.7 }}
            >
              {data !== null && (
                <img
                  className="w-100 h-100 object-fit-cover"
                  src={data.coverImageUrl}
                  alt=""
                />
              )}
              <div className="position-absolute w-100 h-100 top-0 left-0 d-flex flex-column justify-content-end">
                <div className="pt-5 px-3 pb-4 vertical-gradient-overlay d-flex flex-column align-items-start">
                  <div
                    className="bg-dark opacity-75 px-2 py-1 text-white rounded mb-2"
                    style={{ fontSize: 12 }}
                  >
                    <i className="bi bi-geo-alt me-1"></i>
                    {data?.content.length ?? 0}
                  </div>
                  <h4 className="text-white lh-base mb-0">
                    <strong>{data?.title}</strong>
                  </h4>
                </div>
              </div>
            </div>

            {/* UserProfile */}
            <div
              className="d-flex flex-row align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => data !== null && navigate(`/user/${data.userId}`)}
            >
              <div
                className="rounded-circle overflow-hidden"
                style={{ height: 48, width: 48 }}
              >
                {data !== null && (
                  <img
                    className="w-100 h-100 rounded-circle"
                    src={data.userImageUrl}
                    alt=""
                  />
                )}
              </div>
              <p className="ms-2 mb-0 lh-sm small">
                <strong>{data?.userUsername}</strong>
                <br />
                <span className="text-body-secondary">{data?.createdAt}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-8 d-flex flex-column gap-5">
          {data !== null && data.description !== "" && (
            <div className="p-3" style={{ fontSize: 15 }}>
              <MultilineText value={data.description} />
            </div>
          )}
          {data?.content.map((content, index) => (
            <div key={index} className="p-3">
              {content.title !== "" && (
                <h5 className="mb-3">{content.title}</h5>
              )}
              {content.description !== "" && (
                <p className="mb-3" style={{ fontSize: 15 }}>
                  <MultilineText value={content.description} />
                </p>
              )}
              <div className="border rounded p-3">
                <h6 className="mb-1">
                  <strong>{content.spaceName}</strong>
                </h6>
                <p className="mb-0 text-body-secondary small mb-2">
                  <span>{content.spaceType}</span>
                  <i className="bi bi-dot"></i>
                  <span>{content.spaceAreas}</span>
                </p>
                <div className="overflow-x-scroll mb-2">
                  <div className="d-flex flex-row gap-2">
                    {content.imageUrls.map((url, index) => (
                      <img
                        key={url + index}
                        className="rounded"
                        src={url}
                        alt=""
                        style={{
                          height: 260,
                          width: 182,
                          objectFit: "cover",
                        }}
                      />
                    ))}
                  </div>
                </div>
                <p className="text-body-tertiary mb-0" style={{ fontSize: 13 }}>
                  <MultilineText value={content.daylogDescription} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CurationDetailPage;
