import React from "react";
import PlusSvg from "../asset/plus.svg";
import styles from "../style/QuestionSuggestionChatItem.module.scss";
import Text from "../asset/text.json";
import { BotMessage } from "../types";
import { useChat } from "../service/useChat";
import { useLanguage } from "../service/useLanguage";

type QuestionSuggestionMessageProps = {
  message: Extract<BotMessage, { type: "question-suggestions" }>;
};

const QuestionSuggestionMessage: React.FC<QuestionSuggestionMessageProps> = (
  props,
) => {
  const { send } = useChat();
  const { code } = useLanguage();

  if (props.message.suggestions.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <p className={styles.descriptionText}>{Text[code].NEXT_QUESTION}</p>
      <div className={styles.suggestionListDiv}>
        {props.message.suggestions.map((suggestion, index) => (
          <div
            key={index}
            className={styles.suggestionDiv}
            onClick={() => send(suggestion)}
          >
            <p className={styles.suggestionText}>{suggestion}</p>
            <img src={PlusSvg} alt="" className={styles.plusButton} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionSuggestionMessage;
